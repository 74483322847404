<template>
  <div class="mx-2">
    <!-- Titulo -->
    <div>
      <div class="text-right">
        <GenericButtonDownload
          :tooltip_text="`EVALUACIONES XLSX`"
          :click_button="DownloadEvaluationsEgressProfileXLS"
          :class="`mt-2 mr-3`"
          :icon_excel="true"
        ></GenericButtonDownload>
        <!-- <b-button
          size="sm"
          class="mr-2 noprint"
          @click="DownloadEvaluationsEgressProfileXLS"
        >
          <b-icon-download class="mr-1 icon-download"></b-icon-download>
          EVALUACIONES XLS
        </b-button> -->
      </div>
    </div>
    <!-- Tabla -->
    <div class="mt-2">
      <template>
        <div class="learning-experiences-container">
          <b-table-simple
            bordered
            no-border-collapse
            small
            responsive
            :sticky-header="stickyHeaderSize"
            class="txt-custom"
          >
            <b-thead>
              <b-tr>
                <b-th class="text-center primary-header" colspan="16"
                  >Evaluaciones</b-th
                >
              </b-tr>
              <b-tr>
                <b-th class="text-center align-middle secondary-header"
                  >Sigla Asignatura</b-th
                >
                <b-th class="text-center align-middle secondary-header"
                  >Asignatura</b-th
                >
                <b-th class="text-center align-middle secondary-header"
                  >Nivel del Plan</b-th
                >
                <b-th class="text-center align-middle secondary-header"
                  >Tipo de evaluación</b-th
                >
                <b-th class="text-center align-middle secondary-header"
                  >Resultado de aprendizaje</b-th
                >
                <b-th class="text-center align-middle secondary-header"
                  >Indicadores de Logro</b-th
                >
                <b-th class="text-center align-middle secondary-header"
                  >Situación evaluativa</b-th
                >
                <b-th
                  class="text-center align-middle secondary-header"
                  v-b-tooltip.v-secondary.noninteractive="
                    'Descripción evaluación (Mencionar las características principales que configuran la actividad de evaluación: características principales del encargo, ejecución práctica, pruebas escritas o presentación)'
                  "
                  >Descripción evaluación
                </b-th>
                <b-th class="text-center align-middle secondary-header"
                  >Agente evaluativo</b-th
                >
                <b-th class="text-center align-middle secondary-header"
                  >Ponderación evaluación</b-th
                >
                <b-th class="text-center align-middle secondary-header"
                  >Ponderación por situación evaluativa</b-th
                >
                <b-th class="text-center align-middle secondary-header"
                  >Horas de Docencia Dirigida (pedagógicas)</b-th
                >
                <b-th class="text-center align-middle secondary-header"
                  >Horas de Trabajo Autónomo Preparación y/o Ejecución
                  (cronológicas)</b-th
                >
                <b-th class="text-center align-middle secondary-header"
                  >Tipo de trabajo</b-th
                >
                <b-th class="text-center align-middle secondary-header"
                  >Ambiente</b-th
                >
                <b-th class="text-center align-middle secondary-header"
                  >Examinador/a</b-th
                >
              </b-tr>
            </b-thead>
            <b-tbody>
              <template v-if="matter_evaluations_by_egress_profile.length > 0">
                <template
                  v-for="(
                    matter, index
                  ) in matter_evaluations_by_egress_profile"
                >
                  <template
                    v-for="(
                      matter_evaluation, index2
                    ) in matter.matter_evaluations"
                  >
                    <b-tr
                      v-for="(
                        evaluation_test, index3
                      ) in matter_evaluation.tests"
                      :key="
                        'matter' +
                        index +
                        'matter_evalaution ' +
                        matter_evaluation.id +
                        index2 +
                        'index_test' +
                        index3
                      "
                    >
                      <!-- Sigla Asignatura -->
                      <b-td
                        v-if="index2 == 0 && index3 == 0"
                        :rowspan="getRowEvaluation(matter.matter_evaluations)"
                        class="text-left align-middle"
                      >
                        {{ matter.matter_code }}
                      </b-td>
                      <!-- Asignatura -->
                      <b-td
                        v-if="index2 == 0 && index3 == 0"
                        :rowspan="getRowEvaluation(matter.matter_evaluations)"
                        class="text-left align-middle"
                      >
                        {{ matter.matter_name }}
                      </b-td>
                      <!-- Nivel del Plan -->
                      <b-td
                        v-if="index2 == 0 && index3 == 0"
                        :rowspan="getRowEvaluation(matter.matter_evaluations)"
                        class="text-center align-middle p-4"
                      >
                        {{ matter.matter_plan_level }}
                      </b-td>
                      <!-- Tipo de evaluación -->
                      <b-td
                        v-if="index3 == 0"
                        :rowspan="matter_evaluation.tests.length"
                        class="text-left"
                      >
                        {{ matter_evaluation.evaluation_type }}
                      </b-td>
                      <!-- Resultado de aprendizaje -->
                      <b-td
                        v-if="index3 == 0"
                        :rowspan="matter_evaluation.tests.length"
                      >
                        {{ matter_evaluation.learning_results }}
                      </b-td>
                      <!-- Indicadores de Logro -->
                      <b-td
                        v-if="index3 == 0"
                        :rowspan="matter_evaluation.tests.length"
                      >
                        {{ matter_evaluation.evaluation_criterias_micro }}
                      </b-td>
                      <!-- Situación evaluativa -->
                      <b-td class="text-left">
                        {{ evaluation_test.evaluation_situation }}
                      </b-td>
                      <!-- Descripción evaluación -->
                      <b-td
                        v-if="index3 == 0"
                        :rowspan="matter_evaluation.tests.length"
                      >
                        <div
                          class="rich-text-content description-evaluation"
                          v-html="matter_evaluation.description"
                        ></div>
                      </b-td>
                      <!-- Agente evaluativo -->
                      <b-td class="text-left">
                        <div>
                          {{ evaluation_test.evaluative_agent }}
                        </div>
                      </b-td>
                      <!-- Ponderación evaluación -->
                      <b-td
                        v-if="index3 == 0"
                        :rowspan="matter_evaluation.tests.length"
                      >
                        {{ matter_evaluation.weighing }}%</b-td
                      >
                      <!-- Ponderación por Situacion Evaluativa -->
                      <b-td> {{ evaluation_test.weighing }}% </b-td>
                      <!-- Horas de Docencia Dirigida (pedagógicas) -->
                      <b-td
                        v-if="index3 == 0"
                        :rowspan="matter_evaluation.tests.length"
                        class="text-left"
                      >
                        <div
                          v-for="(
                            pedagogical_hour, index
                          ) in matter_evaluation.pedagogical_hours.filter(
                            (x) => x.hours > 0
                          )"
                          :key="index"
                        >
                          <p>
                            {{ pedagogical_hour.hours }} Hrs.
                            {{
                              pedagogical_hour.study_environment != null
                                ? pedagogical_hour.study_environment
                                : `Sin ${$getVisibleNames(
                                    "mesh.studyenvironment",
                                    false,
                                    "Ambiente de Aprendizaje"
                                  )}`
                            }}
                          </p>
                        </div>
                      </b-td>
                      <!-- Horas de Trabajo Autónomo Preparación y/o Ejecución
                  (cronológicas) -->
                      <b-td
                        v-if="index3 == 0"
                        :rowspan="matter_evaluation.tests.length"
                        class="text-left"
                      >
                        <div
                          v-for="(
                            chronological_hour, index
                          ) in matter_evaluation.chronological_hours.filter(
                            (x) => x.hours > 0
                          )"
                          :key="index"
                        >
                          <p>
                            {{ chronological_hour.hours }} Hrs.
                            {{
                              chronological_hour.study_environment != null
                                ? chronological_hour.study_environment
                                : `Sin ${$getVisibleNames(
                                    "mesh.studyenvironment",
                                    false,
                                    "Ambiente de Aprendizaje"
                                  )}`
                            }}
                          </p>
                        </div>
                      </b-td>
                      <!-- Tipo de trabajo -->
                      <b-td>
                        {{ evaluation_test.work_type }}
                      </b-td>
                      <!-- Ambiente -->
                      <b-td
                        v-if="index3 == 0"
                        :rowspan="matter_evaluation.tests.length"
                      >
                        {{ matter_evaluation.study_environments }}
                      </b-td>
                      <!-- Examinador/a -->
                      <b-td>
                        {{ evaluation_test.examiner }}
                      </b-td>
                    </b-tr>
                    <!-- Template para cuando no existen test -->
                    <template v-if="matter_evaluation.tests.length == 0">
                      <b-tr
                        :key="
                          'matter' +
                          index +
                          'matter_evalaution ' +
                          matter_evaluation.id +
                          index2
                        "
                      >
                        <!-- Sigla Asignatura -->
                        <b-td
                          v-if="index2 == 0"
                          :rowspan="matter.matter_evaluations.length"
                          class="text-left align-middle"
                        >
                          {{ matter.matter_code }}
                        </b-td>
                        <!-- Asignatura -->
                        <b-td
                          v-if="index2 == 0"
                          :rowspan="matter.matter_evaluations.length"
                          class="text-left align-middle"
                        >
                          {{ matter.matter_name }}
                        </b-td>
                        <!-- Nivel del Plan -->
                        <b-td
                          v-if="index2 == 0"
                          :rowspan="matter.matter_evaluations.length"
                          class="text-center align-middle"
                        >
                          {{ matter.matter_plan_level }}
                        </b-td>
                        <!-- Tipo de evaluación -->
                        <b-td class="text-left">
                          {{ matter_evaluation.evaluation_type }}
                        </b-td>
                        <!-- Resultado de aprendizaje -->
                        <b-td>
                          {{ matter_evaluation.learning_results }}
                        </b-td>
                        <!-- Indicadores de Logro -->
                        <b-td>
                          {{ matter_evaluation.evaluation_criterias_micro }}
                        </b-td>
                        <!-- Situación evaluativa -->
                        <b-td class="text-left"> </b-td>
                        <!-- Descripción evaluación -->
                        <b-td>
                          <div
                            class="rich-text-content description-evaluation"
                            v-html="matter_evaluation.description"
                          ></div>
                        </b-td>
                        <!-- Agente evaluativo -->
                        <b-td class="text-left">
                          <div></div>
                        </b-td>
                        <!-- Ponderación evaluación -->
                        <b-td> {{ matter_evaluation.weighing }}%</b-td>
                        <!-- Ponderación por Situacion Evaluativa -->
                        <b-td> </b-td>
                        <!-- Horas de Docencia Dirigida (pedagógicas) -->
                        <b-td class="text-left">
                          <div
                            v-for="(
                              pedagogical_hour, index
                            ) in matter_evaluation.pedagogical_hours.filter(
                              (x) => x.hours > 0
                            )"
                            :key="index"
                          >
                            <p>
                              {{ pedagogical_hour.hours }} Hrs.
                              {{
                                pedagogical_hour.study_environment != null
                                  ? pedagogical_hour.study_environment
                                  : `Sin ${$getVisibleNames(
                                      "mesh.studyenvironment",
                                      false,
                                      "Ambiente de Aprendizaje"
                                    )}`
                              }}
                            </p>
                          </div>
                        </b-td>
                        <!-- Horas de Trabajo Autónomo Preparación y/o Ejecución
                  (cronológicas) -->
                        <b-td class="text-left">
                          <div
                            v-for="(
                              chronological_hour, index
                            ) in matter_evaluation.chronological_hours.filter(
                              (x) => x.hours > 0
                            )"
                            :key="index"
                          >
                            <p>
                              {{ chronological_hour.hours }} Hrs.
                              {{
                                chronological_hour.study_environment != null
                                  ? chronological_hour.study_environment
                                  : `Sin ${$getVisibleNames(
                                      "mesh.studyenvironment",
                                      false,
                                      "Ambiente de Aprendizaje"
                                    )}`
                              }}
                            </p>
                          </div>
                        </b-td>
                        <!-- Tipo de trabajo -->
                        <b-td> </b-td>
                        <!-- Ambiente -->
                        <b-td>
                          {{ matter_evaluation.study_environments }}
                        </b-td>
                        <!-- Examinador/a -->
                        <b-td> </b-td>
                      </b-tr>
                    </template>
                  </template>
                </template>
              </template>
              <!-- Template cuando no hay evaluaciones -->
              <template
                v-else-if="matter_evaluations_by_egress_profile.length == 0"
              >
                <b-tr>
                  <b-td colspan="16">
                    <strong> No hay datos para mostrar </strong>
                  </b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
export default {
  name: "MatterProgramByEgressProfileView",
  components: {
    GenericButtonDownload: () =>
      import("@/components/reusable/Buttons/GenericButtonDownload"),
  },
  props: {},
  data() {
    return {
      matter_evaluations_by_egress_profile: [],
      egress_profile_id: Number(this.$route.params.egress_profile_id),
      stickyHeaderSize: "1000px",
    };
  },
  computed: {},
  methods: {
    getRowEvaluation(evaluations) {
      let row = 0;
      evaluations.forEach((x) => {
        if (x.tests.length == 0) {
          row++;
        } else row += x.tests.length;
      });
      return row;
    },
    getMatterEvaluationsByEgressProfile() {
      this.$restful
        .Get(
          `/teaching/matter_evaluations_by_egress_profile/?egress_profile=${this.egress_profile_id}`
        )
        .then((response) => {
          this.matter_evaluations_by_egress_profile = response;
        });
    },
    DownloadEvaluationsEgressProfileXLS() {
      this.$restful
        .Get(
          `/teaching/egress_profile_evaluations_excel_report/?egress_profile=${this.egress_profile_id}`
        )
        .then((response) => {
          if (response && response.file_url)
            window.open(response.file_url, "_blank");
        });
    },
    updateStickyHeaderSize() {
      const viewportHeight = window.innerHeight;
      const newStickyHeaderSize = viewportHeight * 1 + "px"; // Por ejemplo, 100% de la altura del viewport
      this.stickyHeaderSize = newStickyHeaderSize;
    },
  },
  mounted() {
    this.updateStickyHeaderSize();
    window.addEventListener("resize", this.updateStickyHeaderSize);
  },
  created() {
    this.$store
      .dispatch(names.FETCH_EGRESS_PROFILE, this.egress_profile_id)
      .then((response) => {
        this.$store.commit(
          names.MUTATE_TITLE_OF_THE_PRINT,
          `Listado de ${this.$getVisibleNames(
            "evaluations2.evaluation",
            true,
            "Evaluaciones"
          )} del ${this.$getVisibleNames(
            "mesh.egressprofile",
            false,
            "Perfil de Egreso"
          )}- ${response.name}`
        );
      });
    this.getMatterEvaluationsByEgressProfile();
  },
  beforeDestroy() {
    this.$store.commit(names.RESET_TITLE_OF_THE_PRINT);
    window.removeEventListener("resize", this.updateStickyHeaderSize);
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
.primary-header {
  background: black !important;
  color: white !important;
  font-weight: bold !important;
  font-size: 26px;
}
.secondary-header {
  background: #d0cece !important;
}
.txt-custom {
  font-size: 9pt;
}
.description-evaluation {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}
@media print {
  .learning-experiences-container {
    zoom: 52%;
    display: table;
    table-layout: inherit;
  }
  .description-evaluation {
    max-height: none;
    height: auto;
    overflow-y: hidden;
  }
}
</style>